<template>
  <div class="goods-shoot-content">
    <div class="header">
      <div class="title">
        <span class="left-icon"></span>
        <span class="text">销售额与订单数量对比分析图</span>
      </div>
      <div class="btn">
        <el-button class="btn-plain" type="primary" @click="getStatisticalData" plain>查看数据</el-button>
        <el-button class="btn-plain" type="primary" @click="downloadTemplate" plain>下载模板</el-button>
        <el-button class="button" @click="upload">上传数据</el-button>
      </div>
    </div>
    <div class="echarts-content">
      <div class="echarts">
        <el-card class="sale">
          <div class="noData" v-show="!saleData.length">
            <img src="../../../../assets/images/student/Frame.png" alt="" />
            <p>暂无数据</p>
          </div>
          <!-- <div class="saleEcharts" id="saleEcharts" v-show="saleData.length"></div> -->
          <SaleEcharts :saleData="saleData" :istitle="istitle" :echartsHeight="'360px'"></SaleEcharts>
        </el-card>
        <el-card class="order">
          <div class="noData" v-show="!orderData.length">
            <img src="../../../../assets/images/student/Frame.png" alt="" />
            <p>暂无数据</p>
          </div>
          <!-- <div class="orderEcharts" id="orderEcharts" v-show="orderData.length"></div> -->
          <OrderEcharts :orderData="orderData" :istitle="istitle" :lenged="lenged" :echartsHeight="'360px'">
          </OrderEcharts>
        </el-card>
      </div>
      <el-card class="number">
        <div class="noData" v-show="!numberData.length">
          <img src="../../../../assets/images/student/Frame.png" alt="" />
          <p>暂无数据</p>
        </div>
        <!-- numberEcharts -->
        <!-- <div class="numberEcharts" id="numberEcharts" v-show="numberData.length"></div> -->
        <NumberEcharts :numberData="numberData" :istitle="istitle" :lenged="lenged" :echartsHeight="'350px'">
        </NumberEcharts>
      </el-card>
    </div>
    <!-- 上传数据弹框 -->
    <el-dialog title="上传数据" :visible.sync="uploadDialogVisible" width="560px" :before-close="handleClose">
      <div>
        <p><span style="color:red">*</span>销售额趋势分析图</p>
        <div class="upload">
          <el-upload action="stuEcommerceDataReport/uploadAnalysisData" :data="{ type: 1 }" :headers="headerParam"
            :on-success="handleCoverSuccessAnswer1" :on-error="imageError" :before-upload="beforImg"
            :show-file-list="false">
            <el-button type="primary" size="medium" plain class="upload-btn">点击上传</el-button>
          </el-upload>
          <p class="fileInfo" v-show="analysis_data.sale_trend_analysis_file.name">
            已选择：<span class="filename">{{
              analysis_data.sale_trend_analysis_file.name
            }}</span><i class="el-icon-close" @click="deleteFile(1)"></i>
          </p>
        </div>
      </div>
      <div>
        <p><span style="color:red">*</span>订单数量与用户增长对比分析图</p>
        <div class="upload">
          <el-upload action="stuEcommerceDataReport/uploadAnalysisData" :data="{ type: 2 }" :headers="headerParam"
            :on-success="handleCoverSuccessAnswer2" :on-error="imageError" :before-upload="beforImg"
            :show-file-list="false">
            <el-button type="primary" size="medium" plain class="upload-btn">点击上传</el-button>
          </el-upload>
          <p class="fileInfo" v-show="analysis_data.order_num_comparison_analysis_file.name">
            已选择：<span class="filename">{{
              analysis_data.order_num_comparison_analysis_file.name
            }}</span><i class="el-icon-close" @click="deleteFile(2)"></i>
          </p>
        </div>
      </div>
      <div>
        <p><span style="color:red">*</span>销售额与订单数量对比分析图</p>
        <div class="upload">
          <el-upload action="stuEcommerceDataReport/uploadAnalysisData" :data="{ type: 3 }" :headers="headerParam"
            :on-success="handleCoverSuccessAnswer3" :on-error="imageError" :before-upload="beforImg"
            :show-file-list="false">
            <el-button type="primary" size="medium" plain class="upload-btn">点击上传</el-button>
          </el-upload>
          <p class="fileInfo" v-show="analysis_data.sell_amout_comparison_analysis_file.name">
            已选择：<span class="filename">{{
              analysis_data.sell_amout_comparison_analysis_file.name
            }}</span><i class="el-icon-close" @click="deleteFile(3)"></i>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUploadForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "@/utils/slice_upload";
import SaleEcharts from "@/components/student/exam/ECommerceDataAnalysisAndApplication/saleEcharts.vue"
import OrderEcharts from "@/components/student/exam/ECommerceDataAnalysisAndApplication/orderEcharts.vue"
import NumberEcharts from "@/components/student/exam/ECommerceDataAnalysisAndApplication/numberEcharts.vue"
import {
  collectUp_video,
  collectMerge,
  StuCommerceSavaAnswer,
  StuCommerceGetList,
  stuEcommerceDataReportSave,
  stuEcommerceDataReportInfo,
  stuEcommerceDataReportGetStatisticalData,
} from "@/utils/apis";
import axios from "axios";
import echarts from "echarts";
export default {
  name: "GoodsVideoShoot1",
  data() {
    return {
      uploadDialogVisible: false,
      analysis_data: {
        sale_trend_analysis_file: {
          url: "",
          name: "",
        }, //销售额趋势分析图-文件
        order_num_comparison_analysis_file: {
          url: "",
          name: "",
        }, //订单数量与用户增长对比分析图-文件
        sell_amout_comparison_analysis_file: {
          url: "",
          name: "",
        }, //销售额与订单数量对比分析图-文件
      },
      saleData: [],
      orderData: [],
      numberData: [],
      uploadFormRules: [],
      headerParam: {
        Authorization: localStorage.getItem("studentToken") || "",
      },
      istitle: true,
      lenged: false,
      echartsHeight: ''
    };
  },
  created() {
    this.getEcharsData();

  },
  methods: {
    getEcharsData() {
      stuEcommerceDataReportInfo().then((res) => {
        // console.log("获取数据", res);

        if (res.code == 200 && res.data.stu_answer.file) {
          this.saleData = res.data.stu_answer.sale_trend_analysis_data;
          this.orderData =
            res.data.stu_answer.order_num_comparison_analysis_data;
          this.numberData =
            res.data.stu_answer.sell_amout_comparison_analysis_data;
            this.analysis_data=res.data.stu_answer.file
        } else {
          // this.$message.warning(res.msg);
        }
      });
    },
    getStatisticalData() {
      // /student/trainCenter/ECommerceDataAnalysisAndApplication/viewImportedAdminData
      // this.$router.push('/student/trainCenter/ECommerceDataAnalysisAndApplication/viewImportedAdminData',{query:this.$route.query.op_id

      // })
      // this.$router.push({
      //   path:'/student/trainCenter/ECommerceDataAnalysisAndApplication/viewImportedAdminData',
      //   query:{
      //     op_id:this.$route.query.op_id
      //   }
      // })
      const { href } = this.$router.resolve({
        path: '/student/trainCenter/ECommerceDataAnalysisAndApplication/viewImportedAdminData',
        query: {
          op_id: this.$route.query.op_id
        }
      });
      window.open(href, '_blank');

    },
    downloadTemplate() {
      let timer = null
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.download(1);
        this.download(2);
        this.download(3);
      }, 500)

    },
    download(type) {
      let params = {
        type: type,
      };
      let _this = this;
      axios({
        method: "get",
        params: params,
        url: "stuEcommerceDataReport/downloadTemplate",
        responseType: "blob",
        headers: { Authorization: _this.teacherToken },
      })
        .then((res) => {
          let filename = "";
          switch (type) {
            case 0:
              filename = "电子商务数据报表模板.xlsx";
              break;
            case 1:
              filename = "销售趋势分析图模板.xlsx";
              break;
            case 2:
              filename = "订单数量与用户增长对比分析图模板.xlsx";
              break;
            case 3:
              filename = "销售额与订单数量对比分析图模板.xlsx";
              break;
          }
          let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = filename; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        })
        .catch((err) => {
          // console.log("err", err);
        });
    },
    upload() {
      this.uploadDialogVisible = true;
      console.log(this.analysis_data.sale_trend_analysis_file.url);
    },
    handleClose() {
      this.analysis_data.sale_trend_analysis_file.url = "";
      this.analysis_data.sale_trend_analysis_file.name = "";
      this.analysis_data.order_num_comparison_analysis_file.url = "";
      this.analysis_data.order_num_comparison_analysis_file.name = "";
      this.analysis_data.sell_amout_comparison_analysis_file.url = "";
      this.analysis_data.sell_amout_comparison_analysis_file.name = "";
      this.uploadDialogVisible = false;
    },
    submitUploadForm() {
      if (!this.analysis_data.sale_trend_analysis_file.url) {
        return this.$message.warning("请上传销售趋势分析文件！");
      }
      if (!this.analysis_data.order_num_comparison_analysis_file.url) {
        return this.$message.warning("请上传订单数量与用户增长对比分析文件！");
      }
      if (!this.analysis_data.sell_amout_comparison_analysis_file.url) {
        return this.$message.warning("请上传销售额与订单数量对比分析图！");
      }
      let params = this.analysis_data;
      // console.log(params);
      stuEcommerceDataReportSave(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.saleData = []
          this.orderData = []
          this.numberData = []
          // this.handleClose();
          this.uploadDialogVisible = false;
          this.getEcharsData();

        }
      });
    },
    handleCoverSuccessAnswer1(response) {
      //销售趋势分析图-文件
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // console.log(response);
        this.analysis_data.sale_trend_analysis_file.url =
          response.data.file.url;
        this.analysis_data.sale_trend_analysis_file.name =
          response.data.file.name;
        // this.saleData = response.data.list;
        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },

    handleCoverSuccessAnswer2(response) {
      //订单数量与用户增长对比分析图-文件
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // console.log(response);
        this.analysis_data.order_num_comparison_analysis_file.url =
          response.data.file.url;
        this.analysis_data.order_num_comparison_analysis_file.name =
          response.data.file.name;
        // this.orderData = response.data.list;
        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },
    handleCoverSuccessAnswer3(response) {
      //销售额与订单数量对比分析图-文件
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // console.log(response);
        this.analysis_data.sell_amout_comparison_analysis_file.url =
          response.data.file.url;
        this.analysis_data.sell_amout_comparison_analysis_file.name =
          response.data.file.name;
        // this.numberData = response.data.list;
        //无域名
      } else {
        this.$message.error(response.msg);
      }
    },
    imageError(err) {
      // console.log(err);
    },
    beforImg(file) {
      file;
    },
    deleteFile(type) {
      if (type == 1) {
        this.analysis_data.sale_trend_analysis_file.url = "";
        this.analysis_data.sale_trend_analysis_file.name = "";
      }
      if (type == 2) {
        this.analysis_data.order_num_comparison_analysis_file.url = "";
        this.analysis_data.order_num_comparison_analysis_file.name = "";
      }
      if (type == 3) {
        this.analysis_data.sell_amout_comparison_analysis_file.url = "";
        this.analysis_data.sell_amout_comparison_analysis_file.name = "";
      }
    },

  },
  components: {
    SaleEcharts,
    OrderEcharts,
    NumberEcharts
  }
};
</script>

<style scoped lang="scss">
.goods-shoot-content {
  width: 100%;
  height: 100%;
  //   background-color: pink;
  padding: 20px;
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
  }
}

.title {
  margin-bottom: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;

  .left-icon {
    display: inline-block;
    width: 4px;
    height: 16px;
    background-color: #2dc079;
    margin-right: 6px;
    border-radius: 2px;
  }
}

.btn-plain {
  color: #1cb064;
  background: #e8f7f0;
  border-color: #a4dfc1;
}

.button {
  color: #fff;
  background-color: #1cb064;
  border-color: #1cb064;
}

.echarts-content {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  padding-top: 20px;
}

.echarts {
  width: 100%;
  display: flex;
  flex: 1;

  .sale {
    flex: 1;
  }

  .order {
    flex: 1;
    margin-left: 20px;
  }

  .saleEcharts,
  .orderEcharts {
    height: 360px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

.number {
  flex: 1;
  margin-top: 20px;

  .numberEcharts {
    height: 350px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

.upload {
  display: flex !important;
  align-items: center;
}

.upload-btn {
  margin-right: 20px;
}

.fileInfo {
  font-size: 14px;
}

.filename {
  color: #1cb064;
}

.el-icon-close {
  color: red;
  margin-left: 5px;
}

.el-card {
  width: 100%;
  height: 100%;
}

::v-deep .el-card__body {
  width: 100%;
  height: 100%;
}

.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 160px;
    height: 160px;
    display: block;
  }

  p {
    display: block;
    color: #666666;
  }
}

::v-deep .el-dialog__body {
  padding-top: 10px !important;
}
</style>