<template>
  <div>
    <div class="saleEcharts" :style="{ height: echartsHeight }" ref="saleEcharts" id="saleEcharts"
      v-show="saleData.length"></div>
  </div>
</template>

<script>
export default {
  props: ['saleData', "istitle", "lenged", 'echartsHeight'],
  data() {
    return {

    }
  },
  watch: {
    saleData: function (newval, oldval) {
      this.getSaleCondition()
    },
    // istitle:function(newval,oldval){
    //     this.getSaleCondition()
    // },
    // lenged:function(newval,oldval){
    //     this.getSaleCondition()
    // },
    // echartsHeight:function(newval,oldval){
    //     this.getSaleCondition()
    // },

  },
  mounted() { this.getSaleCondition() },
  methods: {
    getSaleCondition() {
      if (!this.saleData.length) return;
      //   console.log(this.saleData,"hhh");
      this.$nextTick(() => {
        // let saleEcharts = this.$echarts.init(
        //   document.getElementById("saleEcharts")
        // );
        let saleEcharts = this.$echarts.init(
          this.$refs.saleEcharts
        );
        // 获取到x轴
        let xAxisData = [];
        let seriesData = [];
        this.saleData.forEach((item) => {
          xAxisData.push(item.date);
          seriesData.push(item.sell_amout);
        });
        // console.log(xAxisData);
        let intervalData = Math.floor(xAxisData.length / 7);
        let title = this.istitle ? '销售额趋势分析图' : ''
        let legend = ''
        if (this.lenged) {
          legend = {
            data: [
              {
                name: "销售额",
                icon: "circle",
                color: "#2dc079",
              },

            ],
            left: 'center',
            bottom: 0,
          }
        } else {
          legend = ''
        }
        let saleEchartsOption = {
          title: {
            text: title,
          },
          color: ["#2dc079", "#2F80ED"],
          tooltip: {
            trigger: "axis",
          },
          legend,
          xAxis: {
            type: "category",
            data: xAxisData,
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999", //刻度线的颜色
              },
            },
            axisLabel: {
              // interval: intervalData,
              // rotate:30
              // textStyle: {
              //   color: "#999",
              // },
            },
            offset: 10,
            textStyle: {
              color: "#ccch", //文字颜色
              fontSize: "6", //文字大小
            },
          },
          yAxis: {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999", //刻度线的颜色
              },
            },
            name: "单位",
          },
          series: [
            {
              name: "销售额",
              data: seriesData,
              type: "line",
              smooth: true,
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#2dc079",
                  },
                },
              },
            },
          ],
        };

        saleEcharts.setOption(saleEchartsOption);
        window.addEventListener("load", function () {
          saleEcharts.resize();
        });
        window.addEventListener("resize", function () {
          saleEcharts.resize();
        });
      });
    },
  },

}
</script>

<style lang="scss" scoped>
.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 160px;
    height: 160px;
    display: block;
  }

  p {
    display: block;
    color: #666666;
  }
}

.saleEcharts {
  // width: 200px;
  height: 300px;
  padding-right: 20px;
  box-sizing: border-box;
}</style>